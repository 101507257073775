import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import blogAvatar from '../images/blog-default.jpg'

const Blogs = () => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiPosts(filter: {categories: {elemMatch: {name: {eq: "blog"}}}},sort: {fields: created_at, order: DESC}){
				edges{
					node{
						id
						post_title
						post_excerpt
						feature_image {
						  childImageSharp {
						    fluid {
						      src
						    }
						  }
						}
					}
				}
			}
		}
	`)

	const blogContent = [];
	query.allStrapiPosts.edges.forEach((item)=>{
		blogContent.push(item.node);
	})
	let blogs = blogContent;
	return(
		<section id="services" className="section-padding bg-gray">
			<div className="container">
				<div className="section-header text-center">
					<h2 className="section-title wow fadeInDown">{`Blogs`}</h2>
					<p dangerouslySetInnerHTML={{__html: `Read the latest news and stories about Ecompim.`}}/>
				</div>
				<div className="row">
					{
						blogs.map((item, index)=>{
							return (
								<div className="col-md-6 col-lg-4 col-xs-12 blog-section" key={`key_${index}`}>
									<div className="services-item wow fadeInRight blog">
										<div className="post-thumb">
											<Link to={item.id}>
												<img src={item.feature_image ? item.feature_image.childImageSharp.fluid.src : blogAvatar} className="feature-image img-thumbnail"/>
											</Link>
										</div>
										<div className="services-content blog">
											<h3><Link to={item.id}>{`${item.post_title.substring(0, 32)}...`}</Link></h3>
											<p>{`${item.post_excerpt.substring(0, 120)}...`}</p>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</section>
	)
}

export default Blogs