import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import SubHeader from '../components/subheader'
import BlogSection from '../components/blogs';

const Blog = () => {
    return (
        <Layout>
            <SEO title="Blogs" />
            <SubHeader title="Blogs"/>
            <BlogSection/>
        </Layout>
    )
}

export default Blog